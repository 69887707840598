<template>
    <div class="container">
        <AppButton @click="showModal = true">Add Recurring Order</AppButton>
        <table v-if="orders">
            <thead>
                <tr>
                    <th style="width: 32%">Email</th>
                    <th style="width: 32%">Type</th>
                    <th style="width: 32%">Day</th>
                    <th style="width: 4%"></th>
                </tr>
            </thead>
            <tbody>
                <!-- eslint-disable-next-line vue/require-v-for-key -->
                <tr v-for="(order, index) in orders" @click="selectedIndex = index; showModal = true;">
                    <td>{{ order.email }}</td>
                    <td>{{ order.frequency_type.charAt(0).toUpperCase()+order.frequency_type.slice(1) }}</td>
                    <td>{{ getDayName(order.day) }}</td>
                    <td><AppButton @click.stop="deleteOrder(index)">&times;</AppButton></td>
                </tr>
            </tbody>
        </table>
    </div>
    <ModalRecurringOrder
        v-if="showModal"
        :order="selectedIndex !== null ? orders[selectedIndex] : null"
        @close="showModal = false; selectedIndex = null;"
        @save="save($event)"
    />
</template>

<script>
import ModalRecurringOrder from './ModalRecurringOrder.vue';
import {getRecurringOrders, saveRecurringOrders} from '../api.js';

export default {
    components: {ModalRecurringOrder},
    data() {
        return {
            orders: null,
            selectedIndex: null,
            showModal: false,
        };
    },
    created() {
        this.fetch();
    },
    methods: {
        deleteOrder(index) {
            if (confirm('Delete recurring order?')) {
                this.orders.splice(index, 1);
                this.persist();
            }
        },
        async fetch() {
            this.orders = await getRecurringOrders();
        },
        getDayName(number) {
            return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][number - 1];
        },
        async persist() {
            try {
                await saveRecurringOrders(this.orders);
            } catch (e) {
                this.fetch();
            }
        },
        save(data) {
            if (this.selectedIndex === null) {
                this.orders.push(data);
            } else {
                this.orders.splice(this.selectedIndex, 1, data);
            }

            this.showModal = false;
            this.selectedIndex = null;
            this.persist();
        },
    },
};
</script>

<style scoped>
    table {
        margin-top: 20px;
    }

    tbody tr {
        cursor: pointer;
    }

    table .app-button {
        padding: 4px 8px;
    }
</style>
