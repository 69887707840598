<template>
    <div class="app-fetch-select">
        <input
            ref="input"
            v-model="inputText"
            type="text"
            @blur="onInputBlur"
            @focus="onInputFocus"
        />
        <div
            v-if="dropdownItems.length > 0"
            class="dropdown"
        >
            <div
                v-for="item in dropdownItems"
                :key="item"
                @click="onItemClick(item)"
                @mousedown.prevent
            >
                {{ item.label }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        fetch: {
            type: Function,
            required: true,
        },
        modelValue: {
            type: String,
            default: null,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            debounceTimeout: null,
            dropdownItems: [],
            ignoreInputTextChange: false,
            ignoreModelValueChange: false,
            inputText: this.modelValue,
        };
    },
    watch: {
        inputText() {
            if (this.debounceTimeout) {
                clearTimeout(this.debounceTimeout);
            }

            if (this.ignoreInputTextChange) {
                return;
            }

            this.ignoreModelValueChange = true;
            this.$emit('update:modelValue', null);
            this.$nextTick(() => this.ignoreModelValueChange = false);

            this.debounceTimeout = setTimeout(() => {
                if (!this.inputText) {
                    this.dropdownItems = [];
                    return;
                }

                this.fetchDropdownItems();
            }, 200);
        },
        modelValue() {
            if (this.ignoreModelValueChange) {
                return;
            }

            this.ignoreInputTextChange = true;
            this.inputText = this.modelValue;
            this.$nextTick(() => this.ignoreInputTextChange = false);
        },
    },
    methods: {
        async fetchDropdownItems() {
            this.dropdownItems = await this.fetch(this.inputText ? this.inputText : null);
        },
        onInputBlur() {
            this.dropdownItems = [];
            this.ignoreInputTextChange = true;
            this.inputText = this.modelValue;
            this.$nextTick(() => this.ignoreInputTextChange = false);
        },
        onInputFocus() {
            this.$refs.input.select();
        },
        onItemClick(item) {
            this.$emit('update:modelValue', item.value);
            this.dropdownItems = [];
            this.ignoreInputTextChange = true;
            this.inputText = item.value;
            this.$nextTick(() => {
                this.ignoreInputTextChange = false;
                this.$refs.input.select();
            });
        },
    },
};
</script>

<style scoped>
    .app-fetch-select {
        position: relative;
    }

    input {
        cursor: default;
        width: 100%;
    }

    input:focus {
        cursor: text;
    }

    .dropdown {
        background-color: #fff;
        border: 1px solid #aaa;
        border-radius: 2px;
        box-sizing: border-box;
        max-height: 100px;
        overflow-y: auto;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 100;
    }

    .dropdown > div {
        cursor: pointer;
        line-height: 1.5;
        overflow: hidden;
        padding: 0 6px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .dropdown > div:hover {
        background-color: #f0f0f0;
    }
</style>
