<template>
    <nav>
        <div class="container">
            <div class="left">
                <AppButton @click="page = 'new'" :class="{ active: page == 'new' }">New Orders</AppButton>
                <AppButton @click="page = 'completed'" :class="{ active: page == 'completed' }">Complete Orders</AppButton>
                <AppButton @click="page = 'recurring'" :class="{ active: page == 'recurring' }">Recurring Orders</AppButton>
                <AppButton @click="page = 'log'" :class="{ active: page == 'log' }">Log</AppButton>
            </div>
            <div class="right">
                <AppButton @click="logout">Logout</AppButton>
            </div>
        </div>
    </nav>
</template>

<script>
import {logout} from '../api.js';
import {ref, watch} from 'vue';

export default {
    setup(props, context) {
        const page = ref('new');

        watch(page, () => context.emit('nav', page.value));

        context.emit('nav', page.value);

        return { logout, page };
    },
};
</script>

<style scoped>
    nav {
        background-color: #eee;
        font-size: 16px;
        margin-bottom: 15px;
    }

    .container {
        display: flex;
        justify-content: space-between;
        padding: 0;
    }

    .left {
        flex-grow: 1;
    }

    .right {
        flex-grow: 0;
    }

    button.app-button {
        background-color: transparent;
        border: none;
        border-radius: 0;
        color: #000;
    }

    button.app-button:hover, button.app-button.active {
        background-color: #dadada;
    }
</style>
