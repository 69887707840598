<template>
    <AppModal :width="550">
        <template #header>{{ order ? 'Edit' : 'Add' }} Recurring Order</template>
        <div class="field">
            <label for="email">Email</label>
            <AppFetchSelect
                v-model="data.email"
                :fetch="fetchCustomers"
            />
        </div>
        <div class="field">
            <label for="frequency_type">Frequency</label>
            <select id="frequency_type" v-model="data.frequency_type">
                <option value="weekly">Weekly</option>
                <option value="fortnightly">Fortnightly</option>
                <option value="monthly">Monthly</option>
            </select>
        </div>
        <div class="field">
            <label for="day">Day</label>
            <select id="day" v-model="data.day">
                <option value="1">Sunday</option>
                <option value="2">Monday</option>
                <option value="3">Tuesday</option>
                <option value="4">Wednesday</option>
                <option value="5">Thursday</option>
                <option value="6">Friday</option>
                <option value="7">Saturday</option>
            </select>
        </div>
        <div class="field">
            <label for="address">Address</label>
            <textarea id="address" v-model="data.address"></textarea>
        </div>
        <div style="height: 200px; overflow-y: scroll;">
            <table>
                <thead>
                    <tr>
                        <th style="width: 70%">SKU</th>
                        <th style="width: 15%">Qty</th>
                        <th style="width: 5%"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in data.items" :key="item.id">
                        <td>
                            <AppFetchSelect
                                v-model="item.sku"
                                :fetch="fetchProducts"
                            />
                        </td>
                        <td><input v-model="item.quantity" type="number"></td>
                        <td><AppButton @click="data.items.splice(index, 1)">&times;</AppButton></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="margin-top: 10px; text-align: right;">
            <AppButton @click="data.items.push({id: id++})">Add Item</AppButton>
        </div>
        <template #footer>
            <AppButton @click="save">Save</AppButton>
            <AppButton style="margin-left: 10px" @click="$emit('close')">Cancel</AppButton>
        </template>
    </AppModal>
</template>

<script>
import {getCustomers, getProducts} from '../api.js';

export default {
    props: {
        order: {
            type: Object,
            default: null,
        },
    },
    emits: ['close', 'save'],
    data() {
        return {
            data: {items: []},
            id: 1,
        };
    },
    created() {
        if (this.order) {
            let items = [];

            for (let item of this.order.items) {
                let copy = {};
                Object.assign(copy, item);
                copy.id = this.id++;
                items.push(copy);
            }

            Object.assign(this.data, this.order);
            this.data.items = items;
        }
    },
    methods: {
        async fetchCustomers(q) {
            return (await getCustomers(q)).map(p => ({label: p.email+': '+p.name, value: p.email})).filter(c => c.value);
        },
        async fetchProducts(q) {
            return (await getProducts(q)).map(p => ({label: p.sku+': '+p.name, value: p.sku}));
        },
        save() {
            let order = {};
            Object.assign(order, this.data);
            order.day = parseInt(order.day);
            order.items = [];

            for (let item of this.data.items) {
                if (item.sku && item.quantity) {
                    let copy = {};
                    Object.assign(copy, item);
                    delete copy.id;
                    order.items.push(copy);
                }
            }

            for (let attr of ['email', 'frequency_type', 'day', 'address']) {
                if (!order[attr]) {
                    alert ('The '+attr.replace('_', ' ')+' field is required.');
                    return;
                }
            }

            if (order.items.length === 0) {
                alert('Order must have at least one item.');
                return;
            }

            this.$emit('save', order);
        },
    },
};
</script>

<style scoped>
    .field {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .field > :first-child {
        flex-basis: 100px;
    }

    .field > :last-child {
        flex-grow: 1;
    }

    textarea {
        height: 60px;
    }

    table input, table :deep(input) {
        padding: 4px 6px;
        width: 100%;
    }

    table .app-button {
        padding: 4px 8px;
    }
</style>
