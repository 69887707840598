<template>
    <div class="container">
        <textarea v-model="log" disabled></textarea>
    </div>
</template>

<script>
import {getLog} from '../api.js';
import {ref} from 'vue';

export default {
    setup() {
        const log = ref(null);
        getLog().then(data => log.value = data);
        return {log};
    },
};
</script>

<style scoped>
    textarea {
        height: 700px;
        width: 100%;
    }
</style>
