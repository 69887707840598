<template>
    <div class="login">
        <div class="form">
            <div class="logo"><img src="../assets/logo.jpg" width="172"></div>
            <div class="error" :style="{ opacity: error ? 1 : 0 }">Invalid credentials - please try again</div>
            <form @submit.prevent="attempt">
                <label for="username">Username</label>
                <input type="text" id="username" name="username" required v-model="username" autofocus>
                <label for="password">Password</label>
                <input type="password" id="password" name="password" required v-model="password">
                <div class="btn"><AppButton type="submit">Login</AppButton></div>
            </form>
        </div>
    </div>
</template>

<script>
import {login} from '../api.js';
import {nextTick, ref, watch} from 'vue';

export default {
    setup() {
        const username = ref(null);
        const password = ref(null);
        const error = ref(false);

        const attempt = async () => {
            let result = await login(username.value, password.value);

            if (!result) {
                password.value = null;
                nextTick(() => error.value = true);
            }
        };

        watch(() => username.value + password.value, () => error.value = false);

        return {username, password, error, attempt};
    },
};
</script>

<style scoped>
    .login {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .form {
        margin-bottom: 150px;
        width: 300px;
    }

    .logo {
        text-align: center;
    }

    img {
        margin-bottom: 15px;
    }

    .error {
        color: #c00;
        height: 30px;
        transition: opacity 0.4s;
    }

    label, input {
        display: block;
        margin-bottom: 3px;
        width: 100%;
    }

    input {
        margin-bottom: 15px;
    }

    .btn {
        text-align: right;
    }
</style>
