<template>
    <teleport to="body">
        <div class="app-modal">
            <div :style="{width: width + 'px'}">
                <div
                    v-if="$slots.header"
                    class="header"
                >
                    <slot name="header" />
                </div>
                <div class="body">
                    <slot />
                </div>
                <div
                    v-if="$slots.footer"
                    class="footer"
                >
                    <slot name="footer" />
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            default: 400,
        },
    },
};
</script>

<style scoped>
    .app-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        justify-content: space-around;
        z-index: 1000;
    }

    .app-modal > div {
        background-color: #fff;
        border-radius: 4px;
        padding: 25px;
        box-shadow: 0 0 5px #555;
    }

    .header, .body, .footer {
        padding: 0 5px;
    }

    .header {
        border-bottom: 1px solid #ccc;
        font-size: 1.25em;
        margin-bottom: 15px;
        padding-bottom: 10px;
    }

    .footer {
        border-top: 1px solid #ccc;
        margin-top: 15px;
        padding-top: 10px;
    }
</style>
