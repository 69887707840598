import 'normalize.css';
import { createApp } from 'vue';
import App from './App.vue';
import AppButton from './components/AppButton.vue';
import AppFetchSelect from './components/AppFetchSelect.vue';
import AppModal from './components/AppModal.vue';

const app = createApp(App);

app.component('AppButton', AppButton);
app.component('AppFetchSelect', AppFetchSelect);
app.component('AppModal', AppModal);

app.mount('#app');
