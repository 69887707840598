<template>
    <PageLogin v-if="!authenticated"></PageLogin>
    <template v-else>
        <TheNav @nav="page = $event"></TheNav>
        <PageOrders v-if="page == 'new'" :type="page"></PageOrders>
        <PageOrders v-if="page == 'completed'" :type="page"></PageOrders>
        <PageRecurringOrders v-if="page == 'recurring'"></PageRecurringOrders>
        <PageLog v-if="page == 'log'"></PageLog>
    </template>
</template>

<script>
import PageLog from './components/PageLog.vue';
import PageLogin from './components/PageLogin.vue';
import PageOrders from './components/PageOrders.vue';
import PageRecurringOrders from './components/PageRecurringOrders.vue';
import TheNav from './components/TheNav.vue';
import {authenticated} from './api.js';
import {ref} from 'vue';

export default {
    components: {
        PageLog,
        PageLogin,
        PageOrders,
        PageRecurringOrders,
        TheNav,
    },
    setup() {
        const page = ref(null);
        return {authenticated, page};
    },
};
</script>

<style>
    * {
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
    }

    html, body, #app {
        height: 100%;
    }

    input, select {
        border: 1px solid #aeaeb0;
        border-radius: 3px;
        outline: none;
        padding: 7px 12px;
        transition: box-shadow 0.2s;
    }

    input:focus, select:focus {
        box-shadow: 0 0 5px -3px #000;
    }

    .container {
        width: 1200px;
        margin: 0 auto;
        padding: 0 15px;
    }

    table {
        border-spacing: 0;
        text-align: left;
        width: 100%;
    }

    th, td {
        padding: 8px;
    }

    th {
        font-weight: 600;
    }

    td {
        border-top: 1px solid #ccc;
    }
</style>
