import axios from 'axios';
import { computed, ref } from 'vue';

const baseUrl = 'https://cc-api.gonode.nz';
const token = ref(localStorage.getItem('token'));
export const authenticated = computed(() => token.value ? true : false);

async function request(config) {
    config.url = baseUrl + config.url;

    if (!config.headers) {
        config.headers = {};
    }

    config.headers.Authorization = 'Bearer ' + token.value;

    try {
        return await axios(config);
    } catch (error) {
        if (!error.response) {
            alert('It looks like you\'re offline - check your internet connection and try again');
        } else if (error.response.status == 401) {
            logout();
        } else {
            alert('Error ' + error.response.status);
        }

        throw error;
    }
}

export async function login(username, password) {
    try {
        const response = await axios({
            method: 'POST',
            url: baseUrl + '/auth/login',
            data: { email: username, password }
        });

        token.value = response.data.token;
        localStorage.setItem('token', token.value);
    } catch (error) {
        if (!error.response) {
            alert('It looks like you\'re offline - check your internet connection and try again');
        } else if (error.response.status != 401) {
            alert('Error ' + error.response.status);
        }
    }

    return authenticated.value;
}

export function logout() {
    localStorage.removeItem('token');
    token.value = null;
}

export async function getOrders(type, date) {
    if (!['new', 'completed'].includes(type)) {
        return;
    }

    const from = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const to = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);

    const response = await request({
        method: 'GET',
        url: '/orders/' + type,
        params: {
            from: from.toISOString(),
            to: to.toISOString()
        }
    });

    return response.data.orders;
}

export async function submitOrders(type, ids) {
    if (!['new', 'completed'].includes(type)) {
        return;
    }

    await request({
        method: 'POST',
        url: '/orders/sync-' + type,
        data: { ids }
    });
}

export async function getLog() {
    const response = await request({
        method: 'GET',
        url: '/log'
    });

    return response.data;
}

export async function getRecurringOrders() {
    const response = await request({
        method: 'GET',
        url: '/orders/recurring',
    });

    return response.data;
}

export async function saveRecurringOrders(orders) {
    await request({
        method: 'POST',
        url: '/orders/recurring',
        data: orders,
    });
}

export async function getCustomers(q) {
    const response = await request({
        method: 'GET',
        url: '/customers',
        params: {q},
    });

    return response.data;
}

export async function getProducts(q) {
    const response = await request({
        method: 'GET',
        url: '/products',
        params: {q},
    });

    return response.data;
}
