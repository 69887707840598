<template>
    <button :type="type" class="app-button"><slot /></button>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'button',
        },
    },
};
</script>

<style scoped>
    .app-button {
        background-color: #222;
        border: none;
        border-radius: 3px;
        color: #fff;
        cursor: pointer;
        font-weight: 600;
        padding: 8px 20px;
    }

    .app-button:hover {
        background-color: #555;
    }

    .app-button:disabled {
        background-color: #999 !important;
        cursor: not-allowed;
    }
</style>
