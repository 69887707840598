<template>
    <div class="container">
        <div class="actions">
            <div>
                <label>Day:</label>
                <DatePicker v-model="date" inputFormat="dd/MM/yyyy"></DatePicker>
            </div>
            <div>
                <AppButton @click="fetch" :disabled="!date || fetching">
                    Load Orders
                </AppButton>
            </div>
            <AppSpinner v-if="fetching"></AppSpinner>
        </div>
        <div class="actions" style="align-items: center">
            <div class="selected-count">
                {{ selected.length }} order{{ selected.length == 1 ? '' : 's' }} selected
            </div>
            <AppButton @click="submit" :disabled="selected.length == 0 || submitting">
                {{ type == 'new' ? 'Export to Vend' : 'Capture Payments and Complete' }}
            </AppButton>
            <AppSpinner v-if="submitting"></AppSpinner>
        </div>
        <table v-if="orders.length > 0">
            <thead>
                <tr>
                    <th style="width: 16px"><input type="checkbox" @click="toggleSelectAll" :checked="allSelected"></th>
                    <th style="width: 25%">Order Number</th>
                    <th style="width: 50%">Customer</th>
                    <th style="width: 25%">Created</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="order in orders" :key="order.id" :class="{ selected: selected.includes(order.id) }" @click="toggleSelect(order)">
                    <td><input type="checkbox" v-model="selected" :value="order.id" @click.stop></td>
                    <td>{{ order.order_number }}</td>
                    <td>{{ order.customer.first_name }} {{ order.customer.last_name }}</td>
                    <td>{{ delta.format(order.created_at, now) }} ago</td>
                </tr>
            </tbody>
        </table>
        <div v-else class="empty-message">No orders to display. Select a date above and click 'Load Orders' to begin.</div>
    </div>
</template>

<script>
import {computed, onBeforeUnmount, onMounted, ref} from 'vue';
import DatePicker from 'vue3-datepicker';
import {getOrders, submitOrders} from '../api.js';
import AppSpinner from './AppSpinner.vue';
import TimeDelta from 'time-delta';
import TimeDeltaEnLocale from 'time-delta/locales/en';

export default {
    components: { DatePicker, AppSpinner },
    props: {
        type: {
            type: String,
            required: true
        }
    },
    setup(props) {
        let today = new Date();
        today = new Date(today.getFullYear(), today.getMonth(), today.getDate());

        const date = ref(new Date(today.getFullYear(), today.getMonth(), today.getDate()));
        const orders = ref([]);
        const fetching = ref(false);
        const selected = ref([]);
        const submitting = ref(false);

        const fetch = async () => {
            fetching.value = true;
            orders.value = [];
            selected.value = [];

            try {
                const data = await getOrders(props.type, date.value);

                for (let i in data) {
                    data[i].created_at = new Date(data[i].created_at);
                }

                orders.value = data;
                fetching.value = false;
            } catch (error) {
                fetching.value = false;
            }
        };

        const toggleSelect = order => {
            const index = selected.value.indexOf(order.id);

            if (index != -1) {
                selected.value.splice(index, 1);
            } else {
                selected.value.push(order.id);
            }
        };

        const toggleSelectAll = () => {
            if (allSelected.value) {
                selected.value = [];
            } else {
                selected.value = orders.value.map(o => o.id);
            }
        };

        const allSelected = computed(() => {
            let items = orders.value;

            if (items.length == 0) {
                return false;
            }

            for (const item of items) {
                if (!selected.value.includes(item.id)) {
                    return false;
                }
            }

            return true;
        });

        TimeDelta.addLocale(TimeDeltaEnLocale);

        const delta = TimeDelta.create();
        const now = ref(new Date());

        let interval = null;
        onMounted(() => interval = setInterval(() => now.value = new Date(), 1000));
        onBeforeUnmount(() => clearInterval(interval));

        const submit = async () => {
            if (!confirm('Are you sure?')) {
                return;
            }

            submitting.value = true;

            try {
                await submitOrders(props.type, selected.value);

                let newOrders = [];

                for (let order of orders.value) {
                    if (!selected.value.includes(order.id)) {
                        newOrders.push(order);
                    }
                }

                selected.value = [];
                orders.value = newOrders;
                submitting.value = false;
            } catch (error) {
                submitting.value = false;
            }
        };

        return {
            date,
            orders,
            fetch,
            fetching,
            selected,
            submitting,
            toggleSelect,
            toggleSelectAll,
            allSelected,
            delta,
            now,
            submit
        };
    },
};
</script>

<style scoped>
    .actions {
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;
    }

    .actions > * {
        margin-right: 10px;
    }

    label {
        display: block;
        margin-bottom: 3px;
    }

    .selected-count {
        flex-basis: 150px;
        font-weight: 600;
    }

    .selected {
        background-color: #f3f3f3;
    }

    .empty-message {
        margin-top: 30px;
    }
</style>
